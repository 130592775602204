import React, { useState, useEffect, useRef } from "react";
import { ActivityIndicator } from "react-native-web";
import { useTranslation } from "react-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { NextSeo } from "next-seo";

import Header from "../components_web/Header";
import { Constants } from "../src/config";
import Footer from "../components_web/Footer";
import { CardList } from "../src/components/molecules";
import { CImage } from "../src/components/atoms";
import { ArtistDao, FestivalDao, VenueDao } from "../src/DAO";
import SideMenu from "../components_web/SideMenu";
import { nFormatter } from "../helpers";
import moment from "moment";
import { getCookie, setCookies } from "cookies-next";

const isServer = typeof window === "undefined";
const WOW = !isServer ? require("wow.js") : null;

export async function getServerSideProps(context) {
  try {
    const currentHourIndex = parseInt(
      moment.utc(moment.utc().format()).local().format("HH")
    );
    const backgroundImage = `https://ik.imagekit.io/zrbvu2w3h/s3/landing/${
      currentHourIndex === 0 ? 24 : currentHourIndex
    }_Landing.jpg`;
    const backgroundImageMobile = `https://ik.imagekit.io/zrbvu2w3h/s3/landing/${
      currentHourIndex === 0 ? 24 : currentHourIndex
    }_Landing_Mobile.jpg`;

    return { props: { backgroundImage, backgroundImageMobile } };
  } catch (err) {
    console.error("err", err);
    return {
      notFound: true,
    };
  }
}

const Landing = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { backgroundImage, backgroundImageMobile } = props;
  const flatListFestivalRef = useRef(null);
  let pageFestival = 0;
  const flatListArtistRef = useRef(null);
  let pageArtist = 0;
  const flatListVenueRef = useRef(null);
  let pageVenue = 0;
  const [showTagline, setShowTagline] = useState(true);
  const [sideMenuShow, setSideMenuShow] = useState(false);
  const [sideMenuHeaderShow, setSideMenuHeaderShow] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [festivalList, setFestivalList] = useState([]);
  const [festivalSort, setFestivalSort] = useState(
    Constants.sortByAction.SORT_BY_POPULARITY
  );
  const [isLoadingFestival, setIsLoadingFestival] = useState(false);

  const [artistList, setArtistList] = useState([]);
  const [artistSort, setArtistSort] = useState(
    Constants.sortByAction.SORT_BY_POPULARITY
  );
  const [isLoadingArtist, setIsLoadingArtist] = useState(false);

  const [venueList, setVenueList] = useState([]);
  const [venueSort, setVenueSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingVenue, setIsLoadingVenue] = useState(false);

  const [festivalCount, setFestivalCount] = useState("0");
  const [artistCount, setArtistCount] = useState("0");
  const [venueCount, setVenueCount] = useState("0");

  const loadFestival = () => {
    setIsLoadingFestival(true);
    if (getCookie("festival_total")) {
    } else {
      setFestivalCount(getCookie("festival_total"));
    }

    FestivalDao.findWeb({
      pageSize: 1,
    }).then((result) => {
      setFestivalCount(`+${nFormatter(result.totalCount)}`);
      setCookies("festival_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    FestivalDao.findWeb(
      festivalSort === Constants.sortByAction.SORT_BY_TRENDING
        ? {
            sortType: Constants.sortByAction.SORT_BY_TRENDING,
            pageSize: 15,
            dateSortType: Constants.dateFilterType.DATE_FILTER_UPCOMING,
            startDateFrom: moment().format("YYYY-MM-DD"),
            startDateTo: moment().add(1, "month").format("YYYY-MM-DD"),
          }
        : {
            sortType: Constants.sortByAction.SORT_BY_POPULARITY,
            pageSize: 15,
            dateSortType: Constants.dateFilterType.DATE_FILTER_UPCOMING,
          }
    ).then((result) => {
      setIsLoadingFestival(false);
      setFestivalList(result.data);
    });
  };

  const loadArtist = () => {
    setIsLoadingArtist(true);
    if (getCookie("artist_total")) {
      setArtistCount(getCookie("artist_total"));
    }
    const params = {
      sortType: artistSort,
      pageSize: 15,
    };
    if (artistSort == Constants.sortByAction.SORT_BY_TRENDING) {
      params.startDateFrom = moment().format("YYYY-MM-DD");
      params.startDateTo = moment().add(1, "month").format("YYYY-MM-DD");
    }

    ArtistDao.findWeb({
      pageSize: 1,
    }).then((result) => {
      setArtistCount(`+${nFormatter(result.totalCount)}`);
      setCookies("artist_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });
    ArtistDao.findWeb(params).then((result) => {
      setIsLoadingArtist(false);
      setArtistList(result.data);
    });
  };

  const loadVenue = () => {
    setIsLoadingVenue(true);
    if (getCookie("venue_total")) {
    } else {
      setVenueCount(getCookie("venue_total"));
    }

    VenueDao.find({
      pageSize: 1,
    }).then((result) => {
      setVenueCount(`+${nFormatter(result.totalCount)}`);
      setCookies("venue_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: venueSort,
      pageSize: 15,
    }).then((result) => {
      setIsLoadingVenue(false);
      setVenueList(result.data);
    });
  };

  useEffect(() => {
    loadFestival();
  }, [festivalSort]);

  useEffect(() => {
    loadArtist();
  }, [artistSort]);

  useEffect(() => {
    loadVenue();
  }, [venueSort]);

  useEffect(() => {
    new WOW().init();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowTagline(false);
    }, 2000);
  }, []);

  return (
    <div className="sm:h-auto sm:block flex flex-col h-screen overflow-hidden">
      <NextSeo
        title="Soundclub - The Vibe You Need"
        description="Discover music venues, festivals and artists around the world | Join us now."
        canonical="https://www.soundclub.com"
        additionalMetaTags={{
          name: "keywords",
          content:
            "festival, event, artist, music, live music, concert, ticket",
        }}
        openGraph={{
          type: "website",
          locale: "en_EN",
          url: "https://www.soundclub.com",
          title: "Soundclub - The Vibe You Need",
          description:
            "Discover music venues, festivals and artists around the world | Join us now.",
          images: [
            {
              url: "https://www.soundclub.com/images/og-soundclub.png",
              width: 1200,
              height: 630,
              alt: "Soundclub - The Vibe You Need",
              type: "image/png",
            },
          ],
          siteName: "Soundclub - The Vibe You Need",
        }}
        twitter={{
          handle: "@soundclub20",
          site: "@soundclub20",
          cardType: "summary_large_image",
        }}
      />
      <Header setSideMenuShow={setSideMenuShow} />

      <SideMenu
        sideMenuShow={sideMenuShow}
        setSideMenuShow={setSideMenuShow}
        sideMenuHeaderShow={sideMenuHeaderShow}
        setSideMenuHeaderShow={setSideMenuHeaderShow}
      />

      {/* Hero Section Mobile */}
      <div
        id="home"
        style={{
          // backgroundImage: "url(/images/landing_bg_mobile.jpg)",
          backgroundImage: `url(${backgroundImageMobile})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom center",
          backgroundColor: "black",
        }}
        className="sm:hidden block sm:h-auto flex flex-1">
        <div className="w-full h-full flex flex-1 bg-black6">
          <div className="wrapper sm:block flex flex-col justify-between mx-auto relative md:h-800px md:py-0 sm:py-20 py-0">
            {/* <div className="flex sm:flex-col flex-row justify-center items-center absolute sm:pt-304px pt-0 sm:top-0 sm:bottom-auto bottom-10 sm:left-auto left-0 sm:right-auto right-0">
              <a
                className="inline-block sm:pb-30px pb-0 sm:mr-0 mr-60px"
                href="https://play.google.com/store/apps/details?id=com.soundclub"
                target="_blank"
                rel="noreferrer">
                <CImage
                  source="/icons/ic_googleplay.svg"
                  width={25}
                  height={25}
                  layout="fixed"
                  alt="googleplay"
                />
              </a>
              <a
                className="inline-block py-0"
                href="https://apps.apple.com/us/app/soundclub-discover-festivals/id1625045664"
                target="_blank"
                rel="noreferrer">
                <CImage
                  source="/icons/ic_appstore.svg"
                  width={25}
                  height={25}
                  layout="fixed"
                  alt="appstore"
                />
              </a>
            </div> */}
            <div className="flex flex-col justify-center items-center flex-1">
              <h1 className="hidden">Soundclub - The Vibe You Need</h1>
              <div
                data-wow-delay="1.5s"
                data-wow-iteration="1"
                className={`wow fadeOut sm:h-45px h-29px flex flex-row flex-wrap items-center justify-center md:text-40px text-2xl font-bold text-white text-center sm:mb-146px mb-0 ${
                  showTagline ? "" : "opacity-0"
                }`}>
                <div className="wow fadeInDown mr-3">The</div>
                <div className="wow fadeInDown mr-3" data-wow-delay="0.1s">
                  Vibe
                </div>
                <div className="wow fadeInDown mr-3" data-wow-delay="0.2s">
                  You
                </div>
                <div className="wow fadeInDown mr-3" data-wow-delay="0.3s">
                  Need
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center sm:h-full sm:pb-73px mb-0 md:pt-128px sm:pt-20 pt-0">
              <div className="bg-grayBackground3 rounded-full sm:flex hidden flex-row items-center justify-between h-50px md:w-670px w-full mb-98px">
                <div className="flex flex-row items-center">
                  <div
                    className="flex flex-row justify-center items-center bg-gradient-to-bl from-gradient3 via-gradient2 to-gradient1 h-50px w-100px -mr-1 rounded-full cursor-pointer"
                    onClick={() =>
                      router.push(`/explore/venues?search=${keyword}`)
                    }>
                    <img src="/icons/ic_search.svg" alt="search" />
                  </div>
                  <input
                    className="bg-transparent font-regular md:text-lg text-sm text-white placeholder-white3 md:ml-8 ml-4 outline-0 w-400px"
                    placeholder="Venues  |  Festivals  |  Artists"
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? router.push(`/explore/venues?search=${keyword}`)
                        : null
                    }
                  />
                </div>
                <div
                  className="flex flex-row justify-center items-center bg-gradient-to-bl from-gradient3 via-gradient2 to-gradient1 h-50px -mr-1 w-100px w-full rounded-full cursor-pointer"
                  onClick={() => router.push("/explore/venues")}>
                  <img src="/icons/ic_filter.svg" alt="filter" />
                </div>
              </div>

              <div
                className="sm:hidden block w-190px sm:mt-80px mt-0 mb-60px flex flex-row justify-center items-center bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1 py-1 px-6 rounded-full cursor-pointer uppercase font-bold text-white"
                onClick={() => router.push("/explore/venues")}>
                Explore
              </div>
              <div className="relative">
                <div className="flex flex-row items-center mb-20px px-3">
                  <div className="bg-gradient1 h-2px rounded-full w-full" />
                  <div className="md:text-2xl text-lg font-bold text-white uppercase text-center mx-4 relative z-5">
                    {t("key_web_discover")}
                  </div>
                  <div className="bg-gradient1 h-2px rounded-full w-full" />
                </div>
                <div className="flex flex-row items-center">
                  <Link href="/explore/venues">
                    <a className="relative mx-1 sm:w-200px w-85px cursor-pointer">
                      <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                        <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-15px mb-3">
                          {t("key_web_venues")}
                        </div>
                        <div className="md:block hidden">
                          <Image
                            width={28}
                            height={28}
                            src="/icons/ic_search_venue_web.png"
                            alt="venue"
                          />
                        </div>
                        <div className="md:hidden block">
                          <Image
                            width={20}
                            height={20}
                            src="/icons/ic_search_venue_web.png"
                            alt="venue"
                          />
                        </div>
                      </div>
                      <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                        <div className="md:text-xl text-xs font-bold text-white text-center">
                          {venueCount}
                        </div>
                      </div>
                    </a>
                  </Link>
                  <Link href="/explore/festivals">
                    <a className="relative mx-1 sm:w-200px w-85px cursor-pointer">
                      <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                        <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-14px mb-3">
                          {t("key_web_festivals")}
                        </div>
                        <div className="md:block hidden">
                          <Image
                            width={28}
                            height={28}
                            src="/icons/ic_search_festival_web.png"
                            alt="festival"
                          />
                        </div>
                        <div className="md:hidden block">
                          <Image
                            width={20}
                            height={20}
                            src="/icons/ic_search_festival_web.png"
                            alt="festival"
                          />
                        </div>
                      </div>
                      <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                        <div className="md:text-xl text-xs font-bold text-white text-center">
                          {festivalCount}
                        </div>
                      </div>
                    </a>
                  </Link>
                  <Link href="/explore/artists">
                    <a className="relative mx-1 sm:w-200px w-85px cursor-pointer">
                      <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                        <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-15px mb-3">
                          {t("key_web_artists")}
                        </div>
                        <div className="md:block hidden">
                          <Image
                            width={21}
                            height={28}
                            src="/icons/ic_search_artist_web.png"
                            alt="artist"
                          />
                        </div>
                        <div className="md:hidden block">
                          <Image
                            width={15}
                            height={20}
                            src="/icons/ic_search_artist_web.png"
                            alt="artist"
                          />
                        </div>
                      </div>
                      <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                        <div className="md:text-xl text-xs font-bold text-white text-center">
                          {artistCount}
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex-1">&nbsp;</div>
          </div>
        </div>
      </div>

      {/* Hero Section Desktop */}
      <div
        id="home"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom center",
        }}
        className="sm:block hidden sm:h-auto h-full flex flex-1">
        <div className="w-full h-full flex flex-1 bg-black6">
          <div className="wrapper sm:block flex flex-col justify-center mx-auto relative md:h-800px md:py-0 sm:py-20 py-0 sm:pt-0 pt-20 sm:pb-0 pb-32">
            {/* <div className="flex sm:flex-col flex-row justify-center items-center absolute sm:pt-304px pt-0 sm:top-0 sm:bottom-auto bottom-10 sm:left-auto left-0 sm:right-auto right-0">
              <a
                className="inline-block sm:pb-30px pb-0 sm:mr-0 mr-60px"
                href="https://play.google.com/store/apps/details?id=com.soundclub"
                target="_blank"
                rel="noreferrer">
                <CImage
                  source="/icons/ic_googleplay.svg"
                  width={25}
                  height={25}
                  layout="fixed"
                  alt="googleplay"
                />
              </a>
              <a
                className="inline-block py-0"
                href="https://apps.apple.com/us/app/soundclub-discover-festivals/id1625045664"
                target="_blank"
                rel="noreferrer">
                <CImage
                  source="/icons/ic_appstore.svg"
                  width={25}
                  height={25}
                  layout="fixed"
                  alt="appstore"
                />
              </a>
            </div> */}
            <div className="flex flex-col items-center sm:h-full sm:pb-73px mb-0 md:pt-128px sm:pt-20 pt-0">
              <div
                data-wow-delay="1.5s"
                data-wow-iteration="1"
                className={`wow fadeOut h-45px flex flex-row flex-wrap items-center justify-center md:text-40px text-2xl font-bold text-white text-center sm:mb-146px mb-0 ${
                  showTagline ? "" : "opacity-0"
                }`}>
                <div className="wow fadeInDown mr-3">The</div>
                <div className="wow fadeInDown mr-3" data-wow-delay="0.1s">
                  Vibe
                </div>
                <div className="wow fadeInDown mr-3" data-wow-delay="0.2s">
                  You
                </div>
                <div className="wow fadeInDown mr-3" data-wow-delay="0.3s">
                  Need
                </div>
              </div>
              <form
                action="/explore/venues"
                className="bg-grayBackground3 rounded-full sm:flex hidden flex-row items-center justify-between h-50px md:w-670px w-full mb-98px">
                <div className="flex flex-row items-center">
                  <button
                    type="submit"
                    className="flex flex-row justify-center items-center bg-gradient-to-bl from-gradient3 via-gradient2 to-gradient1 h-50px w-100px -mr-1 rounded-full cursor-pointer"
                    onClick={() =>
                      router.push(`/explore/venues?search=${keyword}`)
                    }>
                    <img src="/icons/ic_search.svg" alt="search" />
                  </button>
                  <input
                    name="search"
                    type="search"
                    aria-label="Search Veneus or Festivals or Artists"
                    className="bg-transparent font-regular md:text-lg text-sm text-white placeholder-white3 md:ml-8 ml-4 outline-0 w-400px"
                    placeholder="Venues  |  Festivals  |  Artists"
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? router.push(`/explore/venues?search=${keyword}`)
                        : null
                    }
                  />
                </div>
                <div
                  className="flex flex-row justify-center items-center bg-gradient-to-bl from-gradient3 via-gradient2 to-gradient1 h-50px -mr-1 w-100px w-full rounded-full cursor-pointer"
                  onClick={() => router.push("/explore/venues")}>
                  <img src="/icons/ic_filter.svg" alt="filter" />
                </div>
              </form>

              <div
                className="sm:hidden block w-190px mt-80px mb-60px flex flex-row justify-center items-center bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1 py-1 px-6 rounded-full cursor-pointer uppercase font-bold text-white"
                onClick={() => router.push("/explore/venues")}>
                Explore
              </div>
              <div className="relative">
                <div className="flex flex-row items-center mb-20px px-3">
                  <div className="bg-gradient1 h-2px rounded-full w-full" />
                  <div className="md:text-2xl text-lg font-bold text-white uppercase text-center mx-9 relative z-5">
                    {t("key_web_discover")}
                  </div>
                  <div className="bg-gradient1 h-2px rounded-full w-full" />
                </div>
                <div className="flex flex-row items-center">
                  <Link href="/explore/venues">
                    <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                      <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                        <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-15px mb-3">
                          {t("key_web_venues")}
                        </div>
                        <div className="md:block hidden">
                          <Image
                            width={28}
                            height={28}
                            src="/icons/ic_search_venue_web.png"
                            alt="venue"
                          />
                        </div>
                        <div className="md:hidden block">
                          <Image
                            width={20}
                            height={20}
                            src="/icons/ic_search_venue_web.png"
                            alt="venue"
                          />
                        </div>
                      </div>
                      <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                        <div className="md:text-xl text-xs font-bold text-white text-center">
                          {venueCount}
                        </div>
                      </div>
                    </a>
                  </Link>
                  <Link href="/explore/festivals">
                    <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                      <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                        <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-14px mb-3">
                          {t("key_web_festivals")}
                        </div>
                        <div className="md:block hidden">
                          <Image
                            width={28}
                            height={28}
                            src="/icons/ic_search_festival_web.png"
                            alt="festival"
                          />
                        </div>
                        <div className="md:hidden block">
                          <Image
                            width={20}
                            height={20}
                            src="/icons/ic_search_festival_web.png"
                            alt="festival"
                          />
                        </div>
                      </div>
                      <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                        <div className="md:text-xl text-xs font-bold text-white text-center">
                          {festivalCount}
                        </div>
                      </div>
                    </a>
                  </Link>
                  <Link href="/explore/artists">
                    <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                      <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                        <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-15px mb-3">
                          {t("key_web_artists")}
                        </div>
                        <div className="md:block hidden">
                          <Image
                            width={21}
                            height={28}
                            src="/icons/ic_search_artist_web.png"
                            alt="artist"
                          />
                        </div>
                        <div className="md:hidden block">
                          <Image
                            width={15}
                            height={20}
                            src="/icons/ic_search_artist_web.png"
                            alt="artist"
                          />
                        </div>
                      </div>
                      <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                        <div className="md:text-xl text-xs font-bold text-white text-center">
                          {artistCount}
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Listing Section */}
      <div className="bg-black md:block hidden">
        <div className="wrapper mx-auto h-auto md:py-87px sm:py-20 py-10">
          <div className="mx-auto 2xl:max-w-1545px max-w-1280px relative">
            {/* Venue */}
            <div className="relative">
              <div className="flex flex-row sm:items-center justify-between px-16">
                <div className="flex sm:flex-row flex-col sm:items-center">
                  <div className="text-2xl font-bold text-white sm:mr-7 sm:mb-0 mb-2">
                    VENUES
                  </div>
                  <div className="flex flex-row items-center">
                    <div
                      className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                        venueSort === Constants.sortByAction.SORT_BY_RECOMMENDED
                          ? "border-white text-white"
                          : "border-transparent text-white3"
                      }`}
                      onClick={() =>
                        setVenueSort(Constants.sortByAction.SORT_BY_RECOMMENDED)
                      }>
                      Recommended
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <div
                      className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                        venueSort === Constants.sortByAction.SORT_BY_TRENDING
                          ? "border-white text-white"
                          : "border-transparent text-white3"
                      }`}
                      onClick={() =>
                        setVenueSort(Constants.sortByAction.SORT_BY_TRENDING)
                      }>
                      Trending
                    </div>
                  </div>
                </div>
                <Link href="/explore/venues">
                  <a className="text-xl font-bold text-white uppercase cursor-pointer">
                    Discover
                  </a>
                </Link>
              </div>
              {venueList.length > 4 && (
                <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      if (pageVenue - 1 >= 0) {
                        pageVenue--;
                      }
                      const newIndex = pageVenue * 4;
                      flatListVenueRef?.current?.scrollToIndex({
                        animated: true,
                        index: newIndex,
                      });
                    }}>
                    <img
                      src="/icons/ic_arrow_left.png"
                      width="40"
                      height="40"
                      alt="arrow"
                    />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      if (pageVenue + 1 < Math.round(venueList.length / 4)) {
                        pageVenue++;
                      }
                      const newIndex = pageVenue * 4;
                      flatListVenueRef?.current?.scrollToIndex({
                        animated: true,
                        index: newIndex,
                      });
                    }}>
                    <img
                      src="/icons/ic_arrow_right.png"
                      width="40"
                      height="40"
                      alt="arrow"
                    />
                  </div>
                </div>
              )}
              <CardList
                ref={flatListVenueRef}
                horizontal={true}
                items={venueList}
                isHome={true}
                category={Constants.categoryType.CATEGORY_VENUE}
                style={{ marginTop: 30, paddingHorizontal: 54 }}
                // onItemAction={({ type, item }) => {
                //   if (type == Constants.actionType.ACTION_VIEW) {
                //     var profileId = item.profileId || item.id;
                //     router.push({
                //       pathname: "/profile/details",
                //       query: {
                //         profileId: profileId,
                //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                //         id: item.id,
                //       },
                //     });
                //   }
                // }}
              />
              {isLoadingVenue && <ActivityIndicator />}
            </div>

            {/* Festival */}
            <div className="relative">
              <div className="flex flex-row sm:items-center justify-between px-16 mt-14">
                <div className="flex sm:flex-row flex-col sm:items-center">
                  <div className="text-2xl font-bold text-white sm:mr-7 sm:mb-0 mb-2">
                    FESTIVALS
                  </div>
                  <div className="flex flex-row items-center">
                    <div
                      className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                        festivalSort ===
                        Constants.sortByAction.SORT_BY_POPULARITY
                          ? "border-white text-white"
                          : "border-transparent text-white3"
                      }`}
                      onClick={() =>
                        setFestivalSort(
                          Constants.sortByAction.SORT_BY_POPULARITY
                        )
                      }>
                      Popular
                    </div>
                    <div
                      className={`text-base font-medium border-b-2 cursor-pointer pb-5px ${
                        festivalSort === Constants.sortByAction.SORT_BY_TRENDING
                          ? "border-white text-white"
                          : "border-transparent text-white3"
                      }`}
                      onClick={() =>
                        setFestivalSort(Constants.sortByAction.SORT_BY_TRENDING)
                      }>
                      Trending
                    </div>
                  </div>
                </div>
                <Link href="/explore/festivals">
                  <a className="text-xl font-bold text-white uppercase cursor-pointer">
                    Discover
                  </a>
                </Link>
              </div>
              {festivalList.length > 4 && (
                <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      if (pageFestival - 1 >= 0) {
                        pageFestival--;
                      }
                      const newIndex = pageFestival * 4;
                      flatListFestivalRef?.current?.scrollToIndex({
                        animated: true,
                        index: newIndex,
                      });
                    }}>
                    <img
                      src="/icons/ic_arrow_left.png"
                      width="40"
                      height="40"
                      alt="arrow"
                    />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      if (
                        pageFestival + 1 <
                        Math.round(festivalList.length / 4)
                      ) {
                        pageFestival++;
                      }
                      const newIndex = pageFestival * 4;
                      flatListFestivalRef?.current?.scrollToIndex({
                        animated: true,
                        index: newIndex,
                      });
                    }}>
                    <img
                      src="/icons/ic_arrow_right.png"
                      width="40"
                      height="40"
                      alt="arrow"
                    />
                  </div>
                </div>
              )}
              <CardList
                ref={flatListFestivalRef}
                horizontal={true}
                items={festivalList}
                isHome={true}
                category={Constants.categoryType.CATEGORY_FESTIVAL}
                style={{ marginTop: 30, paddingHorizontal: 54 }}
                // onItemAction={({ type, item }) => {
                //   if (type == Constants.actionType.ACTION_VIEW) {
                //     var profileId = item.profileId || item.id;
                //     router.push({
                //       pathname: "/profile/details",
                //       query: {
                //         profileId: profileId,
                //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                //         id: item.id,
                //       },
                //     });
                //   }
                // }}
              />
              {isLoadingFestival && <ActivityIndicator />}
            </div>

            {/* Artist */}
            <div className="relative">
              <div className="flex flex-row sm:items-center justify-between px-16 mt-14">
                <div className="flex sm:flex-row flex-col sm:items-center">
                  <div className="text-2xl font-bold text-white sm:mr-7 sm:mb-0 mb-2">
                    ARTISTS
                  </div>
                  <div className="flex flex-row items-center">
                    <div
                      className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                        artistSort === Constants.sortByAction.SORT_BY_POPULARITY
                          ? "border-white text-white"
                          : "border-transparent text-white3"
                      }`}
                      onClick={() =>
                        setArtistSort(Constants.sortByAction.SORT_BY_POPULARITY)
                      }>
                      Popular
                    </div>
                    <div
                      className={`text-base font-medium border-b-2 cursor-pointer pb-5px ${
                        artistSort === Constants.sortByAction.SORT_BY_TRENDING
                          ? "border-white text-white"
                          : "border-transparent text-white3"
                      }`}
                      onClick={() =>
                        setArtistSort(Constants.sortByAction.SORT_BY_TRENDING)
                      }>
                      Trending
                    </div>
                  </div>
                </div>
                <Link href="/explore/artists">
                  <a className="text-xl font-bold text-white uppercase cursor-pointer">
                    Discover
                  </a>
                </Link>
              </div>
              {artistList.length > 6 && (
                <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      if (pageArtist - 1 >= 0) {
                        pageArtist--;
                      }
                      const newIndex = pageArtist * 6;
                      flatListArtistRef?.current?.scrollToIndex({
                        animated: true,
                        index: newIndex,
                      });
                    }}>
                    <img
                      src="/icons/ic_arrow_left.png"
                      width="40"
                      height="40"
                      alt="arrow"
                    />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      if (pageArtist + 1 < Math.round(artistList.length / 6)) {
                        pageArtist++;
                      }
                      const newIndex = pageArtist * 6;
                      flatListArtistRef?.current?.scrollToIndex({
                        animated: true,
                        index: newIndex,
                      });
                    }}>
                    <img
                      src="/icons/ic_arrow_right.png"
                      width="40"
                      height="40"
                      alt="arrow"
                    />
                  </div>
                </div>
              )}
              <CardList
                ref={flatListArtistRef}
                horizontal={true}
                items={artistList}
                category={Constants.categoryType.CATEGORY_ARTIST}
                style={{ marginTop: 30, paddingHorizontal: 54 }}
                // onItemAction={({ type, item }) => {
                //   if (type == Constants.actionType.ACTION_VIEW) {
                //     var profileId = item.profileId || item.id;
                //     router.push({
                //       pathname: "/profile/details",
                //       query: {
                //         profileId: profileId,
                //         category: Constants.categoryType.CATEGORY_ARTIST,
                //         id: item.id,
                //       },
                //     });
                //   }
                // }}
              />
              {isLoadingArtist && <ActivityIndicator />}
            </div>
          </div>
        </div>
      </div>

      {/* App Section Desktop */}
      {/* <div
        style={{
          backgroundImage: "url(/images/landing_bg_new.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "bottom center",
        }}
        className="sm:block hidden relative">
        <div className="wrapper mx-auto relative md:h-800px h-auto md:py-87px sm:py-20 py-10">
          <div className="flex flex-col justify-center items-center h-full">
            <div className="sm:text-40px flex justify-center items-center h-49px text-lg font-bold text-white text-center drop-shadow-3xl sm:mb-51px mb-6">
              {t("key_web_home_app_1")}
            </div>
            <CImage
              source="/images/app_screenshot_new_2.png"
              width={747}
              height={356}
              layout="fixed"
              alt="screenshot app"
            />
            <div className="flex flex-row items-center mb-24px mt-54px">
              <a
                href="https://play.google.com/store/apps/details?id=com.soundclub"
                className="-ml-10 pr-8"
                target="_blank"
                rel="noreferrer"
                style={{ height: 55 }}>
                <CImage
                  source="/images/download-googleplay.svg"
                  width={197}
                  height={55}
                  layout="fixed"
                  alt="playstore"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/soundclub-discover-festivals/id1625045664"
                target="_blank"
                rel="noreferrer"
                style={{ height: 51 }}>
                <CImage
                  source="/images/download-appstore.svg"
                  width={152}
                  height={51}
                  layout="fixed"
                  alt="appstore"
                />
              </a>
            </div>
            <div className="sm:text-40px flex justify-center items-center h-49px text-lg font-bold text-white text-center drop-shadow-3xl">
              {t("key_web_home_app_4")}
            </div>
          </div>
        </div>
      </div> */}

      <div className="sm:block hidden">
        <Footer />
      </div>
    </div>
  );
};

export default Landing;
