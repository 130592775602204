import React from "react";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import Image from "next/image";

import { CImage } from "../src/components/atoms";

const Header = ({ setSideMenuShow }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-realblack">
      <div className="md:block hidden border-b border-grayBackground">
        <div className="wrapper mx-auto">
          <div className="flex flex-row py-2 items-center justify-between">
            <div className="flex flex-row items-center">
              <div className="pr-4">
                <Link prefetch={false} href="/">
                  <a className="text-sm text-white font-bold">Soundclub</a>
                </Link>
              </div>
              <div className="px-4">
                <a
                  className="text-sm font-regular text-grayText2"
                  href="https://mag.soundclub.com/?_ga=2.17916089.182590428.1668666706-77631929.1660638547&_gl=1*tdlo3r*_ga*Nzc2MzE5MjkuMTY2MDYzODU0Nw..*_ga_3MY0S4TRP8*MTY2ODY5MjU1MS4zMC4xLjE2Njg2OTI5NzYuMC4wLjA.*_ga_JFWYYYJKKT*MTY2ODY5MjU1MC4xNi4xLjE2Njg2OTI5ODAuMC4wLjA.">
                  {t("key_web_mag")}
                </a>
              </div>
              <div className="px-4">
                <a
                  className="text-sm font-regular text-grayText2"
                  href="https://pro.soundclub.com/?_ga=2.17916089.182590428.1668666706-77631929.1660638547&_gl=1%2Atdlo3r%2A_ga%2ANzc2MzE5MjkuMTY2MDYzODU0Nw..%2A_ga_3MY0S4TRP8%2AMTY2ODY5MjU1MS4zMC4xLjE2Njg2OTI5NzYuMC4wLjA.%2A_ga_JFWYYYJKKT%2AMTY2ODY5MjU1MC4xNi4xLjE2Njg2OTI5ODAuMC4wLjA.">
                  {t("key_web_pro")}
                </a>
              </div>
              {/* <div className="px-4">
                <a
                  className="text-sm font-regular text-grayText2"
                  href="https://store.soundclub.com/">
                  {t("key_web_store")}
                </a>
              </div> */}
            </div>
            <div className="flex flex-row items-center">
              <div className="border border-tabBottomColor rounded-full inline-block px-3 pb-1 mr-6 w-104px text-center">
                <Link prefetch={false} href="/soundclub-pro/">
                  <a className="text-sm font-semibold text-white">
                    {t("key_web_apply")}
                  </a>
                </Link>
              </div>
              <div className="flex flex-row items-center">
                <div className="px-1" style={{ height: 18 }}>
                  <a href="https://web.facebook.com/Soundclub20/">
                    <Image
                      src="/icons/ic_facebook_circle.svg"
                      width={18}
                      height={18}
                      alt="facebook"
                    />
                  </a>
                </div>
                <div className="px-1" style={{ height: 18 }}>
                  <a href="https://www.instagram.com/soundclub20">
                    <Image
                      src="/icons/ic_instagram_circle.svg"
                      width={19}
                      height={18}
                      alt="instagram"
                    />
                  </a>
                </div>
                <div className="px-1" style={{ height: 18 }}>
                  <a href="https://twitter.com/soundclub20">
                    <Image
                      src="/icons/ic_twitter_circle.svg"
                      width={18}
                      height={18}
                      alt="twitter"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper-no-padding mx-auto py-4 sm:px-9 px-4">
        <div className="flex flex-row items-center justify-between">
          {/* Desktop */}
          <div
            className="sm:block hidden cursor-pointer"
            onClick={() => setSideMenuShow(true)}
            style={{ height: 20 }}>
            <CImage
              source="/icons/ic_menu.svg"
              width={29}
              height={20}
              layout="fixed"
              alt="menu"
            />
          </div>
          {/* Mobile */}
          <div
            className="sm:hidden block cursor-pointer"
            onClick={() => setSideMenuShow(true)}
            style={{ height: 22 }}>
            <CImage
              source="/icons/ic_menu_mobile.svg"
              width={30}
              height={22}
              layout="fixed"
              alt="menu"
            />
          </div>

          {/* Desktop */}
          <Link prefetch={false} href="/">
            <a className="sm:block hidden" style={{ height: 52.8 }}>
              <CImage
                source="/images/logo_new.png"
                width={182}
                height={52.8}
                layout="fixed"
                alt="soundclub logo"
              />
            </a>
          </Link>
          {/* Mobile */}
          <Link prefetch={false} href="/">
            <a className="sm:hidden block" style={{ height: 34 }}>
              <CImage
                source="/images/logo_new.png"
                width={120}
                height={34}
                layout="fixed"
                alt="soundclub logo"
              />
            </a>
          </Link>
          <div className="w-8">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
