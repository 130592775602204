import Image from "next/image";
import Link from "next/link";
import React from "react";

const SideMenu = ({
  sideMenuShow,
  setSideMenuShow,
  sideMenuHeaderShow,
  setSideMenuHeaderShow,
}) => {
  return (
    <div
      className={`fixed top-0 left-0 bg-black7 h-screen overflow-y-auto w-305px ${
        sideMenuShow
          ? "translate-x-0 opacity-100"
          : "-translate-x-full opacity-0"
      } transition-all duration-300 h-full z-[999]`}>
      <div className="pt-10 pl-8">
        <div
          className="cursor-pointer inline-block"
          onClick={() => setSideMenuShow(false)}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.646446 1.35355C0.451185 1.15829 0.451185 0.841709 0.646446 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L8.5 7.79289L15.6464 0.646447C15.8417 0.451184 16.1583 0.451184 16.3536 0.646447C16.5488 0.841709 16.5488 1.15829 16.3536 1.35355L9.20711 8.5L16.3536 15.6464C16.5488 15.8417 16.5488 16.1583 16.3536 16.3536C16.1583 16.5488 15.8417 16.5488 15.6464 16.3536L8.5 9.20711L1.35355 16.3536C1.15829 16.5488 0.841709 16.5488 0.646447 16.3536C0.451185 16.1583 0.451185 15.8417 0.646447 15.6464L7.79289 8.5L0.646446 1.35355Z"
              fill="white"></path>
          </svg>
        </div>
      </div>
      <div
        id="sub-menu-prelaunch"
        className="border-b border-white2 pt-7 pb-4 pl-8">
        <div
          className="text-base text-white font-bold cursor-pointer"
          onClick={() => setSideMenuHeaderShow(!sideMenuHeaderShow)}>
          Soundclub
        </div>
        {sideMenuHeaderShow && (
          <div className="p-0">
            <a
              className="block text-base text-grayText2 font-regular py-3"
              href="https://mag.soundclub.com/?_ga=2.17916089.182590428.1668666706-77631929.1660638547&_gl=1*tdlo3r*_ga*Nzc2MzE5MjkuMTY2MDYzODU0Nw..*_ga_3MY0S4TRP8*MTY2ODY5MjU1MS4zMC4xLjE2Njg2OTI5NzYuMC4wLjA.*_ga_JFWYYYJKKT*MTY2ODY5MjU1MC4xNi4xLjE2Njg2OTI5ODAuMC4wLjA.">
              Mag
            </a>
            <a
              className="block text-base text-grayText2 font-regular py-3"
              href="https://pro.soundclub.com/?_ga=2.17916089.182590428.1668666706-77631929.1660638547&_gl=1%2Atdlo3r%2A_ga%2ANzc2MzE5MjkuMTY2MDYzODU0Nw..%2A_ga_3MY0S4TRP8%2AMTY2ODY5MjU1MS4zMC4xLjE2Njg2OTI5NzYuMC4wLjA.%2A_ga_JFWYYYJKKT%2AMTY2ODY5MjU1MC4xNi4xLjE2Njg2OTI5ODAuMC4wLjA.">
              Pro
            </a>
            {/* <a
              className="block text-base text-grayText2 font-regular py-3"
              href="https://store.soundclub.com/">
              Store
            </a> */}
          </div>
        )}
      </div>
      <div className="pl-8 pt-1 py-3 border-b border-white2">
        <Link prefetch={false} href="/">
          <a className="block text-base text-white font-regular uppercase py-3">
            Home
          </a>
        </Link>
        <Link prefetch={false} href="/charts/festivals">
          <a className="block text-base text-white font-regular uppercase py-3">
            Charts
          </a>
        </Link>
        <Link prefetch={false} href="/playlist">
          <a className="block text-base text-white font-regular uppercase py-3">
            Playlist
          </a>
        </Link>
        <Link prefetch={false} href="/soundclub-pro">
          <a className="block text-base text-white font-regular uppercase py-3">
            Apply
          </a>
        </Link>
        <Link prefetch={false} href="/about">
          <a className="block text-base text-white font-regular uppercase py-3">
            About
          </a>
        </Link>
        <Link prefetch={false} href="/platform">
          <a className="block text-base text-white font-regular uppercase py-3">
            Platform
          </a>
        </Link>
        <Link prefetch={false} href="/careers">
          <a className="block text-base text-white font-regular uppercase py-3">
            Careers
          </a>
        </Link>
        <Link prefetch={false} href="/contact">
          <a className="block text-base text-white font-regular uppercase py-3">
            Contact
          </a>
        </Link>
      </div>
      <div className="flex flex-row items-center border-b border-white2 pl-8 py-6">
        <div className="px-2">
          <a
            href="https://web.facebook.com/Soundclub20/"
            aria-label="Facebook Soundclub">
            <Image
              src="/icons/ic_facebook_circle.svg"
              width="20"
              height="20"
              alt="facebook"
            />
          </a>
        </div>
        <div className="px-2">
          <a
            href="https://www.instagram.com/soundclub20"
            aria-label="Instagram Soundclub">
            <Image
              src="/icons/ic_instagram_circle.svg"
              width="20"
              height="20"
              alt="instagram"
            />
          </a>
        </div>
        <div className="px-2">
          <a
            href="https://twitter.com/soundclub20"
            aria-label="Twitter Soundclub">
            <Image
              src="/icons/ic_twitter_circle.svg"
              width="20"
              height="20"
              alt="twitter"
            />
          </a>
        </div>
      </div>
      <div className="pl-8 pt-1 py-3">
        <Link prefetch={false} href="/help-center">
          <a className="block text-base text-grayText2 font-regular py-3">
            Help Center
          </a>
        </Link>
        <Link
          prefetch={false}
          href="https://drive.google.com/drive/folders/1wsCVka-wEssR_yBk6jWXQG4xuPK8zbgI">
          <a className="block text-base text-grayText2 font-regular py-3">
            Media Kit
          </a>
        </Link>
        <a
          href="/cookie-policy"
          className="block text-base text-grayText2 font-regular py-3">
          Cookies
        </a>
        <Link prefetch={false} href="/legal">
          <a className="block text-base text-grayText2 font-regular py-3">
            Legal
          </a>
        </Link>
        <Link prefetch={false} href="/sitemap.xml">
          <a className="block text-base text-grayText2 font-regular py-3">
            Sitemap
          </a>
        </Link>
      </div>
    </div>
  );
};

export default SideMenu;
