import React from "react";
import { useTranslation } from "react-i18next";
import Link from "next/link";

import { CImage } from "../src/components/atoms";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-realblack">
      <div className="wrapper mx-auto py-5">
        <div className="flex flex-row items-center justify-center">
          <Link prefetch={false} href="/">
            <a style={{ height: 49 }}>
              <CImage
                source="/images/logo_2_new.png"
                width={182}
                height={49}
                layout="fixed"
                alt="soundclub logo"
              />
            </a>
          </Link>
        </div>
      </div>
      <div className="border-y border-grayBackground pb-1">
        <div
          className="wrapper mx-auto"
          style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div className="flex md:flex-row flex-col-reverse py-2 items-center justify-between">
            <div className="flex flex-row md:my-0 my-4 items-center">
              <div className="text-xs font-regular text-grayText2 px-4">
                {t("key_web_copyright")}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="md:px-4 px-1">
                <Link prefetch={false} href="/help-center/">
                  <a className="text-xs font-regular text-grayText2">
                    {t("key_web_help_center")}
                  </a>
                </Link>
              </div>
              <div className="md:px-4 px-1">
                <a
                  className="text-xs font-regular text-grayText2"
                  href="https://drive.google.com/drive/folders/1wsCVka-wEssR_yBk6jWXQG4xuPK8zbgI">
                  {t("key_web_media_kit")}
                </a>
              </div>
              <div className="md:px-4 px-1">
                <a
                  href="/cookie-policy"
                  className="text-xs font-regular text-grayText2">
                  {t("key_web_cookies")}
                </a>
              </div>
              <div className="md:px-4 px-1">
                <Link prefetch={false} href="/legal/">
                  <a className="text-xs font-regular text-grayText2">
                    {t("key_web_legal")}
                  </a>
                </Link>
              </div>
              <div className="md:px-4 px-1">
                <Link prefetch={false} href="/sitemap.xml/">
                  <a className="text-xs font-regular text-grayText2">
                    {t("key_web_sitemap")}
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
